import { Text } from '@chakra-ui/react';
import { ReactElement } from 'react';

export interface ServiceCardProps {
  count?: string;
  title?: string;
  description?: string;
  withBorder?: boolean;
  titleBorder?: boolean;
  customDescription?: ReactElement;
  as?: 'h1' | 'h2' | 'h3' | 'p';
}

const ServiceFeatureCard = ({
  count,
  title,
  description,
  withBorder,
  titleBorder,
  customDescription,
  as = 'h3',
}: ServiceCardProps) => {
  return (
    <section
      className={`${
        !titleBorder && 'mt-8'
      } h-fit break-inside-avoid first:mt-0 ${
        withBorder && 'border-b border-gray-500 pb-8'
      }  `}
    >
      <strong className="font-Rubik text-primary-400  mb-2 block text-2xl leading-[32px] lg:mb-6 lg:text-[32px] lg:leading-[38px]">
        {count}
      </strong>
      <Text
        as={as}
        className="font-Rubik text-gray-primary mb-4 text-xl font-semibold capitalize leading-[24px] lg:text-2xl lg:leading-[24px]"
      >
        {title}
      </Text>
      {titleBorder && (
        <div className="border-primary-500  mb-4 border-b-4 lg:mb-8" />
      )}

      {customDescription ? (
        customDescription
      ) : (
        <Text
          className=" font-Poppins text-gray-secondary whitespace-pre-line text-base leading-6"
          as="p"
        >
          {description}
        </Text>
      )}
    </section>
  );
};

export { ServiceFeatureCard };
