import type { DetailedHTMLProps, InputHTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '../ErrorMessage';
import { Label, LabelProps } from '../Label';

type RadioInput = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'label' | 'id' | 'ref'
>;

interface RadioProps extends RadioInput {
  label?: string;
  id?: string;
  gap?: string;
  radioSize?: string;
  radioLabelClassName?: string;
  setCurrentId?: (_id: string) => void;
  field?: any;
  wrapperClassName?: string;
  radioButtonClassName?: string;
}

const RadioItem = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      id = 'radio',
      label,
      gap = 'space-x-2',
      disabled,
      setCurrentId,
      field,
      wrapperClassName,
      radioLabelClassName,
      value,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();
    return (
      <span className={`inline-flex items-center ${gap} ${wrapperClassName}`}>
        <span className="relative flex items-center">
          <input
            ref={ref}
            id={id}
            type="radio"
            className="peer sr-only"
            {...field}
            {...rest}
            value={value}
            disabled={disabled}
          />

          <div
            onClick={() => {
              if (!disabled) {
                const radio = document.getElementById(id);
                radio?.click();
                setCurrentId?.(id);
              }
            }}
            className={`
            relative flex h-4 w-4 shrink-0 items-center justify-center rounded-full
            border border-gray-300 transition-all
            ${
              disabled
                ? 'bg-gray-secondary cursor-not-allowed !border-gray-600'
                : 'hover:border-primary-600 cursor-pointer'
            }
            ${
              value === field?.value
                ? '!border-primary-600 bg-primary-600'
                : '!border-gray-secondary !h-3 !w-3 border border-solid bg-white'
            }
          `}
          >
            {value === field?.value && (
              <div className="h-2 w-2 rounded-full bg-white" />
            )}
          </div>
        </span>

        {label && (
          <label
            htmlFor={id}
            className={`
            select-none text-sm
            ${
              disabled
                ? 'cursor-not-allowed text-gray-600'
                : 'cursor-pointer text-gray-700'
            }
            ${radioLabelClassName}
          `}
          >
            {t(label)}
          </label>
        )}
      </span>
    );
  }
);
RadioItem.displayName = 'Radio';

export interface RadioGroupProps extends LabelProps {
  children?: ReactNode;
  className?: string;
  error?: string;
  disableErrorPlaceholder?: boolean;
  childrenClassName?: string;
  name?: string;
  requiredTag?: boolean;
}
const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    {
      children,
      name,
      childrenClassName,
      className,
      label,
      required,
      error,
      disableErrorPlaceholder,
      labelClassName,
      ...rest
    },
    ref
  ) => {
    let errorName = '';
    if (name?.length) {
      errorName = name;
    } else if (typeof label === 'string') {
      errorName = label;
    }
    return (
      <>
        <div ref={ref} {...rest} className={className}>
          {label ? (
            <Label
              labelClassName={labelClassName}
              label={label}
              required={required}
            />
          ) : null}
          {childrenClassName ? (
            <div className={childrenClassName}>{children}</div>
          ) : (
            children
          )}
        </div>
        {!disableErrorPlaceholder && (
          <ErrorMessage error={error} name={errorName} />
        )}
      </>
    );
  }
);
RadioGroup.displayName = 'RadioGroup';

export { RadioGroup, RadioItem };
