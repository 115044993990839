import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  href: string;
};

export const AnimatedLink = ({ children, href }: Props) => {
  return (
    <a
      href={href}
      target="_blank"
      className="cursor-pointer transition duration-200 ease-in hover:z-50 hover:opacity-80 sm:hover:scale-105"
    >
      {children}
    </a>
  );
};
