import { Blog } from 'lib';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { BlogListItem } from '../..';

export function LatestBlogs({ blogs }: { blogs: Blog[] }) {
  const { t } = useTranslation();

  if (!blogs || !blogs.length) return null;

  return (
    <div className="flex flex-col gap-2">
      <h2 className="font-Rubik m-0 mb-[15px] text-base font-semibold">
        {t('Latest blog')}
      </h2>
      <div className="flex flex-col gap-6">
        {blogs?.map((blog) => (
          <BlogListItem
            bannerImage={blog.banner_image}
            category={blog.category.name}
            date={blog.created_at}
            link={`/blogs/${blog.slug}`}
            title={blog.title}
            key={blog.id}
          />
        ))}
      </div>
    </div>
  );
}
