import { Text } from '@chakra-ui/react';

interface ICoreProps {
  code?: string;
  title?: string;
  description?: string;
}

export const CoreSection = ({ code, title, description }: ICoreProps) => {
  return (
    <div className="8 mb-8 lg:mb-12 lg:flex lg:gap-4">
      <Text className="font-Poppins text-primary-300 text-lg font-semibold leading-[26px]">
        {code}
      </Text>
      <div className="  lg:flex lg:gap-[60px]">
        <div className="border-primary-500 flex border-b-4 pb-4 lg:min-w-[400px] lg:max-w-[400px] ">
          <Text
            as="h3"
            className="font-Rubik text-gray-primary whitespace-pre-line text-[32px] font-bold capitalize leading-[38px]"
          >
            {title}
          </Text>
        </div>
        <Text className="font-Poppins text-gray-secondary mt-4 md:max-w-full lg:mt-0 lg:max-w-[560px] 2xl:max-w-none">
          {description}
        </Text>
      </div>
    </div>
  );
};
