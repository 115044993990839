import { formatDateInWords } from 'lib';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import React from 'react';
import { CategoryTags } from '../..';
import { HTMLContentRender } from '../../molecules/HTMLContentRender';

interface BlogDetailSectionProps {
  category: string;
  title: string;
  date: string;
  bannerImage: string;
  content: string;
}

export function BlogDetailSection({
  category,
  title,
  date,
  bannerImage,
  content,
}: BlogDetailSectionProps) {
  const { i18n } = useTranslation();

  return (
    <div className="blog-detail w-full max-w-[900px]">
      <h1 className="mb-2 mt-[3px] text-2xl font-semibold leading-8 text-black">
        {title}
      </h1>

      <div className="mb-3 flex gap-4 text-sm">
        <p className="m-0 text-[#2E3A59]">
          {formatDateInWords(date, i18n.language)}
        </p>
        <CategoryTags
          tags={[category]}
          className="cursor-default font-semibold text-[#2E3A59]"
        />
      </div>

      <Image
        loading="eager"
        src={bannerImage}
        alt={title}
        height={481}
        width={880}
        objectFit="cover"
      />
      <div className="mt-4">
        <HTMLContentRender content={content} />
      </div>
    </div>
  );
}
