import { Text } from '@chakra-ui/react';
import { TechFeature } from 'components/molecules';
import { useTranslation } from 'next-i18next';
import { ForwardedRef, forwardRef } from 'react';
import { PageProps } from 'shared/interfaces';

export const IncidentManagement = forwardRef(
  ({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();

    return (
      <section id={id} ref={ref} className="mb-16">
        <TechFeature title={t('Incident Management')} withMargin>
          <Text as="h3" className="mb-6 text-lg leading-[26px] text-white">
            {t(
              'We manage incidents and take permanent action at all stages of the business co-creation cycle, including upstream, development, and operations. To prevent recurrence, measures are applied throughout the organization.'
            )}
          </Text>
        </TechFeature>
      </section>
    );
  }
);
