import React from 'react';
import Link from 'next/link';
import { Text } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { typography } from 'ui';
import { INavbarConfig } from 'shared/interfaces';

type Props = {
  item: INavbarConfig;
  active: Function;
  onClick: () => void;
};

const NavItem = ({ item, active, onClick }: Props) => {
  const isActive = active(item.path);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const { t } = useTranslation();

  if (item.type === 'button') {
    return (
      <Link href={item.path || '/'} passHref legacyBehavior>
        <Text
          as="a"
          cursor="pointer"
          {...(isMobile ? typography?.h5 : typography?.h4)}
          display="inline-block"
          fontFamily="'Rubik', sans-serif"
          textAlign="center"
          onClick={onClick}
          width="fit-content"
          className={`rounded-[10px] border border-white px-[15px] py-[5px]  ${
            isActive
              ? 'text-white underline underline-offset-[1.2rem]'
              : 'hover-underline-animation text-primary-200'
          }`}
        >
          {t(`${item.title}`)}
        </Text>
      </Link>
    );
  }

  return (
    <Link href={item.path || '/'} passHref legacyBehavior>
      <Text
        as="a"
        cursor="pointer"
        {...(isMobile ? typography?.h5 : typography?.h4)}
        display="inline-block"
        fontFamily="'Rubik', sans-serif"
        textAlign="center"
        onClick={onClick}
        width="fit-content"
        className={`${
          isActive
            ? 'text-white underline underline-offset-[.6rem]'
            : 'hover-underline-animation text-primary-200'
        }`}
      >
        {t(`${item.title}`)}
      </Text>
    </Link>
  );
};

export default NavItem;
