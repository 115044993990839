import { useTranslation } from 'next-i18next';
import React from 'react';
import { twMerge } from 'tailwind-merge';

export function CategoryTags({
  tags,
  className,
  onClick,
}: {
  tags: string[];
  className?: string;
  onClick?: (_: any) => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-wrap gap-[15px]">
      {tags.map((tag) => (
        <button
          type="button"
          className={twMerge(
            'font-Poppins border-none bg-transparent p-0 text-[#1E6CEA]',
            className
          )}
          key={tag}
          onClick={onClick}
          value={tag}
        >
          {t(tag)}
        </button>
      ))}
    </div>
  );
}
