import { Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
interface IQulaityProps {
  logo?: ReactNode;
  title?: string;
  description?: string;
}

export const QualityOperationItem = ({
  logo,
  title,
  description,
}: IQulaityProps) => {
  return (
    <div className="mb-6 flex gap-4 lg:mb-0">
      <div>{logo}</div>
      <div>
        <Text
          className="font-Poppins text-gray-primary text-lg font-semibold leading-[26px]"
          as="h3"
        >
          {title}
        </Text>
        <Text className="font-Poppins text-gray-secondary mt-2 text-base">
          {description}
        </Text>
      </div>
    </div>
  );
};
