import { Category } from 'lib';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/dist/client/router';
import React from 'react';
import { CategoryTags } from '../../atoms/CategoryTags';

export function CategoryList({
  categoryList,
  isLoading,
}: {
  categoryList: Category[];
  isLoading: boolean;
}) {
  const router = useRouter();
  const { t } = useTranslation();

  if (isLoading) {
    return null;
  }

  return (
    <div className="mt-7 flex flex-col gap-2">
      <h2 className="font-Rubik m-0 text-base font-semibold">
        {t('Categories')}
      </h2>
      <div className="flex w-full flex-wrap gap-[15px]">
        <CategoryTags
          tags={categoryList.map((category) => category.name)}
          className="rounded-sm bg-[#EDF1F7] px-2 py-1 text-base font-medium text-[#1E6CEA]"
          onClick={(e) => {
            router.push(`/news?category=${e.currentTarget.value}`);
          }}
        />
      </div>
    </div>
  );
}
