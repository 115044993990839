import Link from 'next/link';

import {
  CloseIcon,
  Menu,
  MenuLight,
  LogoLgLight,
  LogoLgDark,
} from 'public/assets/images/svg';
import { MouseEvent } from 'react';
import { useRouter } from 'next/router';

type Props = {
  isNavExpanded?: boolean;
  isFooterActive?: boolean;
  handleNavExpanded?: () => void;
  setNavExpanded: Function;
};

export const HeaderTop = ({
  isNavExpanded,
  isFooterActive,
  handleNavExpanded,
  setNavExpanded,
}: Props) => {
  const router = useRouter();

  const handleLogoClick = (
    e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
  ) => {
    e.preventDefault();
    if (typeof window !== 'undefined') {
      localStorage.removeItem('scroll');
      localStorage.removeItem('expandNews');
    }
    if (router.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      router.push('/');
    }
  };

  return (
    <div
      className={`m-auto flex max-w-[1440px] items-center justify-between transition-all ${
        isNavExpanded && 'w-full px-6 py-8 lg:px-[30px] xl:px-[60px]'
      }`}
    >
      <div
        className="inline-block cursor-pointer"
        onClick={() => setNavExpanded(false)}
      >
        <Link href="/" passHref onClick={handleLogoClick}>

          {isNavExpanded || isFooterActive ? (
            <LogoLgDark />
          ) : (
            <LogoLgLight />
          )}

        </Link>
      </div>
      <div className="inline-flex items-center justify-between gap-0 md:gap-4">
        {isNavExpanded ? (
          <div
            onClick={handleNavExpanded}
            className="text-white cursor-pointer"
          >
            <CloseIcon />
          </div>
        ) : (
          <div
            onClick={handleNavExpanded}
            className={`cursor-pointer`}
          >
            {isFooterActive ? <MenuLight /> : <Menu />}
          </div>
        )}
      </div>
    </div>
  );
};
