import { Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface CompanyProps {
  mainTitle?: string;
  subtitle?: string;
  description?: string;
  logo?: ReactNode;
  withBorder?: boolean;
  margin?: string;
}

export const CompanyTopSection = ({
  mainTitle,
  subtitle,
  description,
  logo,
  withBorder,
  margin,
}: CompanyProps) => {
  return (
    <div
      className={`mb-[34px] flex flex-col justify-between	last:mb-0 lg:mb-0 lg:max-w-[480px] ${
        withBorder ? 'lg:border-l-2 lg:pl-[60px]' : 'lg:pr-[60px]'
      }`}
    >
      <div className="flex items-center ">
        {logo}
        <Text
          className="font-Poppins pl-6 text-xl font-semibold leading-[30px] text-white"
          as="p"
        >
          {mainTitle}
        </Text>
      </div>
      <Text
        as="h3"
        className="font-Poppins mt-[18px] block text-lg font-semibold leading-[26px] text-white lg:mt-[21px] lg:whitespace-pre-line "
      >
        {subtitle}
      </Text>

      <Text
        className={` font-Poppins mt-[30px] whitespace-pre-line text-base font-normal ${margin} text-justify text-white lg:mt-[14px]`}
      >
        {description}
      </Text>
    </div>
  );
};
