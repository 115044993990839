import { Box, Text } from '@chakra-ui/react';
import Link from 'next/link';
import { useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { typography } from 'ui';
import { useRouter } from 'next/router';
import { AnimatedLink } from '../../../atoms';
import { PhoneIcon } from '@heroicons/react/outline';
import { LanguageProvider } from '../../../molecules';
import {
  Facebook,
  Linkedin,
  Medium,
  NoteIcon,
  XIcon,
} from '../../../../public/assets/images/svg';
import Image from 'next/image';

const Footer = () => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { pathname, asPath } = useRouter();

  const hoverListClasses = ' hover:underline cursor-pointer block mb-6';

  const activeClasses = 'underline font-semibold';

  return (
    <footer
      id="footer"
      className={`relative z-10 m-auto mt-16 flex items-center justify-center bg-gray-900 text-white md:pl-0 lg:${
        pathname === '/contact' ? 'pl-0' : 'pl-[20%]'
      }`}
    >
      <div
        className={`3xl:max-w-[1440px] mx-6 max-w-[1040px] md:pt-[3rem] lg:mx-6 2xl:mx-0 ${
          pathname === '/contact' && 'lg:max-w-[1440px]'
        }`}
      >
        <div className="mt-[32px] md:flex md:justify-between">
          <div
            style={{ ...typography?.fontRubik, ...typography?.h6 }}
            className="opacity-70 md:w-6/12"
          >
            <div className="md:w-[70%]">
              We are &nbsp;
              <span style={{ ...typography?.fontRubik, ...typography?.h5 }}>
                The Visionary Team,
              </span>
              <Text className="mt-2">
                {t(
                  'A team that accompanies our partners realize shared vision.'
                )}
              </Text>
            </div>
          </div>
          <div className="mt-[16px] opacity-70 md:mt-0 md:w-7/12">
            <Text {...typography?.normal}>
              {t(
                'Co-creation is easier said than done. However, we strongly believe in the co-creation based on our many experiences, industry challenges, and market trends.'
              )}
            </Text>
            <Text {...typography?.normal}>
              {t(
                'Aiming to be a team that accompanies our partners in the realization of their vision and DX, we pursue more efficient and effective forms of co-creation on a daily basis.'
              )}
            </Text>
          </div>
        </div>
        <hr className="mt-[16px] h-[1px] border-none bg-[#FFFFFF] text-[#FFFFFF] opacity-25 md:mb-[50px] md:mt-[32px]" />
        <div className="mt-[32px] justify-between md:flex  ">
          <div className="min-w-[180px]">
            <Text
              as="p"
              className={'mb-6'}
              {...typography?.h5}
              {...typography?.fontRubik}
            >
              {t('About')}
            </Text>
            <Link href="/company#company" passHref legacyBehavior>
              <Text
                as="a"
                className={`${hoverListClasses} ${
                  asPath === '/company#company' ? activeClasses : ''
                } capitalize`}
              >
                {t('Vision/Mission')}
              </Text>
            </Link>
            <Link href="/company#company-profile" passHref legacyBehavior>
              <Text
                className={`${hoverListClasses} ${
                  asPath === '/company#company-profile' ? activeClasses : ''
                }`}
              >
                {t('Company Overview')}
              </Text>
            </Link>
            <Link href="/company#ceo" passHref legacyBehavior>
              <Text
                as="a"
                className={`${hoverListClasses} ${
                  asPath === '/company#ceo' ? activeClasses : ''
                } capitalize`}
              >
                {t('Member Introduction')}
              </Text>
            </Link>
            <Link href="/company#office" passHref legacyBehavior>
              <Text
                as="a"
                className={`${hoverListClasses} ${
                  asPath === '/company#office' ? activeClasses : ''
                }`}
              >
                {t('Offices')}
              </Text>
            </Link>
            <Link href="/corporate-philosophy" passHref legacyBehavior>
              <Text
                as="a"
                className={`${hoverListClasses} ${
                  pathname === '/corporate-philosophy' ? activeClasses : ''
                } capitalize`}
              >
                {t('Corporate philosophy')}
              </Text>
            </Link>
          </div>
          <div className="mt-[32px]  md:mt-0 md:min-w-[180px]">
            <Text
              className="mb-6"
              {...typography?.h5}
              {...typography?.fontRubik}
            >
              {t('Service')}
            </Text>

            <Link href="/service" passHref legacyBehavior>
              <Text
                as="a"
                className={`${hoverListClasses} ${
                  pathname === '/service' ? activeClasses : ''
                } capitalize`}
              >
                {t('Service details')}
              </Text>
            </Link>

            <Link href="/technology" passHref legacyBehavior>
              <Text
                as="a"
                className={`${hoverListClasses} ${
                  pathname === '/technology' ? activeClasses : ''
                }`}
              >
                {t('Technology')}
              </Text>
            </Link>

            <Link href="/quality" passHref legacyBehavior>
              <Text
                className={`${hoverListClasses} ${
                  pathname === '/quality' ? activeClasses : ''
                } capitalize`}
                as="a"
              >
                {t('Quality assurance')}
              </Text>
            </Link>

            <Link href="/security" passHref legacyBehavior>
              <Text
                as="a"
                className={`${hoverListClasses} ${
                  pathname === '/security' ? activeClasses : ''
                }`}
              >
                {t('Security')}
              </Text>
            </Link>
            <Link href="/alliance" passHref legacyBehavior>
              <Text
                as="a"
                className={`${hoverListClasses} ${
                  pathname === '/alliance' ? activeClasses : ''
                }`}
              >
                {t('Alliance')}
              </Text>
            </Link>
          </div>

          <div className="mt-[32px]   md:mt-0 md:min-w-[115px]">
            <Text
              className="mb-6"
              {...typography?.h5}
              {...typography?.fontRubik}
            >
              {t('Career')}
            </Text>

            <Link href="/career" passHref legacyBehavior>
              <Text
                as="a"
                className={`${hoverListClasses} ${
                  pathname === '/career' ? activeClasses : ''
                } capitalize`}
              >
                {t('Career details')}
              </Text>
            </Link>
            <Link href="/core-values" passHref legacyBehavior>
              <Text
                as="a"
                className={`${hoverListClasses} ${
                  pathname === '/core-values' ? activeClasses : ''
                } capitalize`}
              >
                {t('Core value')}
              </Text>
            </Link>
            <Link href="/welfare" passHref legacyBehavior>
              <Text
                as="a"
                className={`${hoverListClasses} ${
                  pathname === '/welfare' ? activeClasses : ''
                }`}
              >
                {t('Benefits')}
              </Text>
            </Link>
            <div className="-ml-2 grid items-start justify-start gap-y-5">
              <LanguageProvider
                listItemClassName="text-[#000]"
                isNavExpanded
                buttonClassName="border border-white px-[15px] py-[5px] rounded-[10px]"
              />
              <div className="ml-2 flex gap-3">
                <div className="">
                  <AnimatedLink href="https://note.com/wesionary_team">
                    <NoteIcon />
                  </AnimatedLink>
                </div>
                <div className="">
                  <AnimatedLink href="https://x.com/wesionary_team">
                    <XIcon />
                  </AnimatedLink>
                </div>
                <div className="">
                  <AnimatedLink href="https://www.facebook.com/wesionaryteam">
                    <Facebook />
                  </AnimatedLink>
                </div>
                <div>
                  <AnimatedLink href="https://www.linkedin.com/company/wesionary-team">
                    <Linkedin />
                  </AnimatedLink>
                </div>
                <div>
                  <AnimatedLink href="https://articles.wesionary.team/">
                    <Medium />
                  </AnimatedLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-[24px] h-[1px] border-none bg-[#FFFFFF] text-[#FFFFFF] opacity-25 md:mb-[30px] md:mt-[50px]" />
        <div className="flex flex-col flex-wrap gap-[32px] text-left md:order-last md:flex-col-reverse md:justify-between md:text-right">
          <Box
            display="flex"
            gap={isMobile ? '18px' : '32px'}
            justifyContent="space-between"
            className="flex-col flex-wrap md:flex-row"
          >
            <Link
              href="/contact"
              passHref
              className={`text-sm hover:underline md:self-end ${
                pathname === '/contact' ? 'font-semibold' : ''
              }`}>

              {t('Contact Us')}

            </Link>

            <Link href="/privacy-policy" passHref legacyBehavior>
              <Text
                as="a"
                className={`text-sm hover:cursor-pointer hover:underline ${
                  pathname === '/privacy-policy' ? 'font-semibold' : ''
                } capitalize`}
              >
                {t('Privacy policy')}
              </Text>
            </Link>
            <Link href="/information-security-policy" passHref legacyBehavior>
              <Text
                as="a"
                className={`text-sm  hover:cursor-pointer hover:underline ${
                  pathname === '/information-security-policy'
                    ? 'font-semibold'
                    : ''
                } capitalize`}
              >
                {t('Information Security Basic Policy')}
              </Text>
            </Link>
            <Link href="/dx-policy" passHref legacyBehavior>
              <Text
                as="a"
                className={`text-sm hover:cursor-pointer hover:underline  ${
                  pathname === '/dx-policy' ? 'font-semibold' : ''
                } capitalize`}
              >
                {t('DX Promotion Policy')}
              </Text>
            </Link>
            <Link href="/report-security" passHref legacyBehavior>
              <Text
                as="a"
                className={`text-sm hover:cursor-pointer hover:underline  ${
                  pathname === '/report-security' ? 'font-semibold' : ''
                } capitalize`}
              >
                {t('Report security issue')}
              </Text>
            </Link>
            <Link href="/quality-policy" passHref legacyBehavior>
              <Text
                as="a"
                className={`text-sm hover:cursor-pointer hover:underline  ${
                  pathname === '/quality-policy' ? 'font-semibold' : ''
                } capitalize`}
              >
                {t('Quality Policy')}
              </Text>
            </Link>
          </Box>
        </div>
        <hr className="my-[24px] h-[1px] border-none bg-[#FFFFFF] text-[#FFFFFF] opacity-25 md:mb-[30px] md:mt-[50px]" />
        <div className="mb-[30px] flex w-full flex-col  justify-between gap-x-[38px] gap-y-6 md:flex-row">
          <div className="3xl:flex-row 3xl:items-center flex flex-col gap-x-[38px] gap-y-6">
            <div className="flex flex-col items-center gap-x-[38px] gap-y-6 md:flex-row">
              <Image
                src="/footer/security_action.svg"
                alt="security action"
                className="h-[104px] w-[104px] object-contain"
                height={104}
                width={104}
              />
              <Image
                src="/footer/dx-logo.svg"
                className="h-[57px] w-[210px] object-contain"
                alt="dx logo"
                height={57}
                width={210}
              />
              <Image
                src="/footer/ISMS-QMS-LOGO.svg"
                className="h-[104px] w-[272px] object-contain"
                alt="ISMS QMS LOGO"
                height={104}
                width={272}
              />
            </div>
            <div className="text-white">
              <Link href="/information-security-policy" passHref className="" legacyBehavior>
                <Text
                  as="a"
                  className="text-sm font-bold capitalize leading-4 underline hover:cursor-pointer"
                >
                  {t('ISMS (Information Security Management System)')}
                </Text>
              </Link>
              <Text as="p" className={`mb-3 mt-2 flex gap-3 text-sm leading-4`}>
                <span> {t('ISO/IEC 27001:2022')}</span>
                <span> {t('Certification number: GIJP-0784-I')}</span>
              </Text>
              <Link href="/quality-policy" passHref legacyBehavior>
                <Text
                  as="a"
                  className="text-sm font-bold capitalize leading-4 underline hover:cursor-pointer"
                >
                  {t('QMS (Quality Management System)')}
                </Text>
              </Link>
              <Text as="p" className={`mt-2 flex gap-3  text-sm leading-4`}>
                <span> {t('ISO 9001:2015')}</span>
                <span> {t('Certification number:GIJP-0784-QC')}</span>
              </Text>
            </div>
          </div>
          <div>
            <Text as="p" className="mb-3 text-sm font-bold">
              {t('Contact our Product Co-Creation Department')}
            </Text>
            <Text as="p" className="mb-3 text-sm font-bold">
              {t('Product Co-Creation Department')}
            </Text>
            <div className="mb-2 flex w-[fit-content] items-center gap-[3px] rounded-[12px] border border-white p-3 text-sm font-semibold">
              <PhoneIcon className="h-3 w-3" />
              <span>03-6873-8467</span>
            </div>
            <Text as="p" className="text-sm">
              {t('Support Hours')}
            </Text>
            <Text as="p" className="text-sm">
              {t('Weekdays 10:00 - 18:00')}
            </Text>
          </div>
        </div>
        <div className="mb-[32px]">
          <p className="flex flex-col flex-wrap gap-[8px] text-xs opacity-70 md:order-first lg:self-start">
            <span className="flex items-start">
              © {new Date().getFullYear()} wesionaryTEAM Co. Ltd. All rights
              reserved.
            </span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
