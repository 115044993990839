import React from 'react';

export interface SpinnerProps {
  color?: string;
  strokeWidth?: number;
}

export const Spinner: React.FC<SpinnerProps> = ({
  color = '#fff',
  strokeWidth = 3,
}) => {
  return (
    <svg
      className="shrink-0 animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M10 1.667A8.333 8.333 0 1 0 18.333 10"
      />
    </svg>
  );
};
