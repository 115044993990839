import { Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'next-i18next';
import { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import { PageProps } from 'shared/interfaces';
import { typography, Button } from 'ui';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { SERVICES_OFFERED } from 'utils/services';
import { ServiceCard } from './ServiceCard';
import Marquee from 'react-fast-marquee';
import { CLIENTS } from 'utils/clients';

const Service = forwardRef(
  ({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const settings = {
      pauseOnHover: true,
      gradient: false,
      speed: 35,
    };
    const { t } = useTranslation();
    const [serviceVideosNumber, setServiceVideosNumber] = useState(0);

    useEffect(() => {
      const serviceVideosNumber = Math.floor(Math.random() * 5);
      setServiceVideosNumber(serviceVideosNumber + 1);
    }, []);

    return (
      <section id={id} ref={ref} className="m-auto mt-8 max-w-xl">
        <div className="grid items-center gap-8 lg:grid-cols-2">
          <div>
            <Text
              as="h1"
              className="lg:leading-big inline text-2xl font-bold leading-[30px] lg:text-5xl"
            >
              <Trans
                i18nKey="problemSolving"
                components={{
                  highlighted: <span className="text-primary-400" />,
                }}
              />
            </Text>

            <div className="mb-8 mt-6 space-y-4">
              <div className="flex items-center">
                <CheckCircleIcon className="text-primary-400 h-6 w-6 shrink-0" />
                <Text as="h2" className="ml-1" {...typography?.medium}>
                  {t(
                    'Providing high-quality web application development and UI/UX design'
                  )}
                </Text>
              </div>
              <div className="flex items-center">
                <CheckCircleIcon className="text-primary-400 h-6 w-6 shrink-0" />
                <Text as="h2" className="ml-1" {...typography?.medium}>
                  {t(
                    'Supporting operational efficiency and value creation through digital transformation (DX) initiatives'
                  )}
                </Text>
              </div>
              <div className="flex items-center">
                <CheckCircleIcon className="text-primary-400 h-6 w-6 shrink-0" />
                <Text as="h2" className="ml-1" {...typography?.medium}>
                  {t(
                    'Validating market adaptability through PoC/MVP development for new business ventures'
                  )}
                </Text>
              </div>
            </div>
            <Button btnVarient="link" href="/contact" type="primary">
              {t('Contact us')}
            </Button>
          </div>
          <div className="order-first lg:order-last">
            {serviceVideosNumber !== 0 && (
              <video
                playsInline
                muted
                height={'100%'}
                width={'1144px'}
                data-testid="video-asset"
                role="video"
                className="3xl:w-full"
                src={`${process.env.NEXT_PUBLIC_STATIC_URL}/videos/service${serviceVideosNumber}.mp4`}
                controls={false}
                autoPlay={true}
                loop={true}
              />
            )}
          </div>
        </div>

        <div className="border-primary-500 mb-8 mt-16 border-l-[6px] pl-4">
          <Text
            as="h2"
            className="text-gray-primary inline text-2xl font-bold leading-[30px] lg:text-[32px] lg:leading-[38px]"
          >
            {t('Services Offered')}
          </Text>
        </div>

        <div className="grid gap-8 md:grid-cols-2">
          {SERVICES_OFFERED.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>

        <div className="border-primary-500 mt-16 border-l-[6px] pl-4">
          <h2 className="text-gray-primary inline text-2xl font-bold leading-[30px] lg:text-[32px] lg:leading-[38px]">
            {t('Companies we worked for')}
          </h2>
        </div>
        <p className="mb-8 mt-1 text-lg text-[#000]">
          {t(
            'We are working on business development and digital transformation'
          )}
        </p>
        <div className="mb-24 grid justify-items-center">
          <div className="absolute left-0 right-0 translate-y-0">
            <Marquee className="" {...settings}>
              {Object.keys(CLIENTS).map((logo, idx) => (
                <div key={idx} className="mx-8 h-12">
                  <img
                    src={`${process.env.NEXT_PUBLIC_STATIC_URL}/client-logo/${logo}`}
                    className="h-full"
                    alt={t(CLIENTS[logo])}
                  />
                </div>
              ))}
            </Marquee>
          </div>
        </div>
      </section>
    );
  }
);

export { Service };
