import axios, {
  type AxiosResponse,
  type AxiosError,
  AxiosRequestConfig,
} from 'axios';

const API = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CMS_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

const requestHandler = async (request: AxiosRequestConfig<any>) => {
  try {
    if (typeof window !== 'undefined') {
      const idToken = localStorage.getItem('token');
      if (request.headers && idToken) {
        request.headers.Authorization = `Bearer ${idToken}`;
      }
    }
  } catch (error) {
    console.error('Request handler error:', error);
  } finally {
    return request;
  }
};

const responseHandler = (response: AxiosResponse) => {
  return response.data;
};

const errorHandler = (error: AxiosError) => {
  if (typeof window !== 'undefined' && error.response?.status === 401) {
    localStorage.removeItem('token');
    window.location.href = '/';
  }
  return Promise.reject(error);
};

API.interceptors.request.use(
  (request) => requestHandler(request),
  (error: AxiosError) => errorHandler(error)
);

API.interceptors.response.use(
  (response) => responseHandler(response),
  (error: AxiosError) => errorHandler(error)
);

export { API };
