import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'next-i18next';
import { Text } from '@chakra-ui/react';
import { typography, Button } from 'ui';
import { TechList } from 'components/molecules';
import { PageProps } from 'shared/interfaces';

const Technology = forwardRef(
  ({ id, className }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();

    return (
      <section
        id={id}
        ref={ref}
        className={`m-auto mt-16 flex max-w-xl flex-col ${className}`}
      >
        <div className="md:pr-0 lg:pr-6 xl:pr-0">
          <div className="pl-0 md:border-l-[6px] md:border-[#3767B1] md:pl-[16px]">
            <Text
              as="h2"
              {...typography?.fontPoppins}
              className=" border-l-[6px] border-[#3767B1] pl-[16px]  text-2xl font-bold md:border-l-0 md:border-none md:pl-0 md:text-3xl"
            >
              {t('Main Technologies')}
            </Text>
            <Text className="mt-[16px] pl-[16px] md:pl-0">
              {t(
                'By carefully selecting and limiting key technologies, we focus our energy on improving security, code quality, and productivity.'
              )}
            </Text>
          </div>
          <TechList />
          <div className="mt-[4rem] flex items-center justify-center md:mt-[32px]">
            <Button
              btnVarient="link"
              href={'/technology'}
              type="secondary"
              className="!w-full md:!w-auto"
            >
              {t('Get more information about our technologies')}
            </Button>
          </div>
        </div>
      </section>
    );
  }
);

export { Technology };
