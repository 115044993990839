import { Text } from '@chakra-ui/react';
import { QualityOperationItem } from 'components/molecules/QualityOperationInformation';
import { useTranslation } from 'next-i18next';
import { ForwardedRef, forwardRef } from 'react';
import { PageProps } from 'shared/interfaces';
import { typography } from 'ui';
import { OPERATION_LIST } from 'utils/quality-of-operation';

export const QualityOfOperations = forwardRef(
  ({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();

    return (
      <section
        id={id}
        ref={ref}
        className="mb-16 mr-0 md:mb-16 lg:mr-[30px] xl:mr-[60px] 3xl:mr-0 "
      >
        <div className="mb-8 border-l-[6px] border-primary-500 pl-4">
          <Text
            as="h2"
            className="mb-4  font-Rubik text-2xl font-bold leading-8 text-black-secondary md:text-medium md:leading-medium"
          >
            {t('Quality of Operation')}
          </Text>
          <Text
            as="p"
            className=" mr-0 font-Poppins font-medium text-gray-secondary lg:mr-4"
            {...typography?.medium}
          >
            {t(
              'Minimize operational burden through automation and monitoring. We concentrate our efforts on critical issues and deliver updates to end users quickly and consistently.'
            )}
          </Text>
        </div>

        <div className="md:gap-4 lg:grid lg:grid-cols-2 lg:gap-y-[60px] lg:gap-x-16">
          {OPERATION_LIST?.map((item, index) => (
            <QualityOperationItem
              logo={item.logo}
              title={t(item.title)}
              description={t(item.description)}
              key={`operation-${index}`}
            />
          ))}
        </div>
      </section>
    );
  }
);
