import LogoDark from './logo-dark.svg';
import LogoLight from './logo-light.svg';
import LogoLgLight from './logoLg.svg';
import LogoLgDark from './logolgdark.svg';
import LogoSM from './logo_sm.svg';
import CloseIcon from './close.svg';
import Menu from './menu.svg';
import DownArrow from './ChevronDownLight.svg';
import ChervonUp from './ChevronUp.svg';
import DownArrowWhite from './chervendown.svg';
import AWSIconOnly from './AWSIconOnly.svg';
import CICDIconOnly from './CICDIconOnly.svg';
import FigmaOnlyIcon from './FigmaIconOnly.svg';
import FlutterIcon from './FlutterIconOnly.svg';
import GCPOnlyIcon from './GCPIconOnly.svg';
import GitHUbIconOnly from './GitHubIconOnly.svg';
import GoIconOnly from './GoIconOnly.svg';
import JiraIconOnly from './JiraIconOnly.svg';
import KubernetesIconOnly from './KuberneticsIconOnly.svg';
import MySqlOnlyIcon from './MySqlIconOnly.svg';
import NextIconOnly from './NextIconOnly.svg';
import NotionIconOnly from './NotionIconOnly.svg';
import ReactIconOnly from './ReactIconOnly.svg';
import SlackIconOnly from './SlackIconOnly.svg';
import TerraFormIconOnly from './TeraformIconOnly.svg';
import FigJamIcon from './FigJam.svg';
import MenuLight from './MenuLight.svg';
import AWSIcon from './AWSICon.svg';
import GCPIcon from './GCPIcon.svg';
import GoIcon from './GoICon.svg';
import KubernetesIcon from './KuberneticsIcon.svg';
import NextIcon from './NextIcon.svg';
import NodeIcon from './nodejs.svg';
import Node from './node.svg';
import PythonIcon from './python.svg';
import ReactIcon from './ReactIcon.svg';
import TerraFormIcon from './TerraFormIcon.svg';
import AttachementIcon from './attachements.svg';
import InfoCircle from './InfoCircular.svg';
import UploadIcon from './upload.svg';
import UserCircular from './userCircular.svg';
import UserSquare from './userSquare.svg';
import DownIconForm from './DownIconForm.svg';
import CheckCircleOutlined from './CheckCircleOutlined.svg';
import Flutter from './flutter.svg';
import Python from './PythonIcon.svg';
import Facebook from './facebook.svg';
import Linkedin from './linkedin.svg';
import Medium from './medium.svg';
import XIcon from './X.svg';
import NoteIcon from './note.svg';

export {
  Node,
  Flutter,
  Python,
  LogoDark,
  LogoLight,
  CloseIcon,
  Menu,
  DownArrow,
  ChervonUp,
  DownArrowWhite,
  AWSIconOnly,
  CICDIconOnly,
  FigmaOnlyIcon,
  GCPOnlyIcon,
  GitHUbIconOnly,
  GoIconOnly,
  JiraIconOnly,
  KubernetesIconOnly,
  MySqlOnlyIcon,
  LogoSM,
  NextIconOnly,
  NotionIconOnly,
  ReactIconOnly,
  SlackIconOnly,
  TerraFormIconOnly,
  FigJamIcon,
  MenuLight,
  AWSIcon,
  FlutterIcon,
  GCPIcon,
  GoIcon,
  KubernetesIcon,
  NextIcon,
  NodeIcon,
  PythonIcon,
  ReactIcon,
  TerraFormIcon,
  AttachementIcon,
  UserCircular,
  UserSquare,
  InfoCircle,
  UploadIcon,
  DownIconForm,
  CheckCircleOutlined,
  LogoLgDark,
  LogoLgLight,
  Facebook,
  Linkedin,
  Medium,
  XIcon,
  NoteIcon,
};
