import { Text } from '@chakra-ui/react';
import { ArrowRightIcon } from '@heroicons/react/solid';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { ElementType } from 'react';
import { typography } from 'ui';

interface ServiceCardProps {
  icon: ElementType;
  title: string;
  description: string;
  link?: string;
}

export const ServiceCard: React.FC<ServiceCardProps> = ({
  icon: Icon,
  title,
  description,
  link,
}) => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#f9fafb] p-[30px]">
      <Icon />
      <Text as="h3" className="mb-3 mt-4" {...typography?.h5}>
        {t(title)}
      </Text>
      <Text
        {...typography?.medium}
        className="text-gray-secondary whitespace-pre-wrap"
      >
        {t(description)}
      </Text>
      {link && (
        <Link
          href={link}
          passHref
          className="text-primary-500 mt-3 flex max-w-max items-center space-x-1 text-lg font-bold">

          <span>{t('Learn More')}</span>
          <ArrowRightIcon className="h-6 w-6" />

        </Link>
      )}
    </div>
  );
};
