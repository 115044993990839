import { Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { ForwardedRef, forwardRef } from 'react';
import { PageProps } from 'shared/interfaces';

const OurOffices = forwardRef(
  ({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();

    return (
      <section
        id={id}
        ref={ref}
        className="m-auto mt-16 flex max-w-xl flex-col"
      >
        <Text
          as="h2"
          className="text-medium mb-8 border-l-[6px]  border-[#3767B1] pl-[16px] font-bold leading-[38px]  text-[#222B45]"
        >
          {t('Office')}
        </Text>
        <div className=" grid gap-4 md:grid-rows-3">
          <div className="grid md:row-span-2 md:grid-cols-2 ">
            <div className="md:pr-20">
              <div className="border-b-2 border-solid border-[#C5CEE0] pb-8 md:mb-8">
                <Text
                  as="h3"
                  className="text-medium text-gray-secondary font-bold leading-[38px] "
                >
                  {t('Main Office')}
                </Text>
                <div className="my-8 text-[#50586C]">
                  <Text>
                    {t('150-6139 2-24-12 Shibuya, Shibuya-ku, Tokyo')}
                  </Text>
                  <Text>{t('Shibuya Scramble Square 39F WeWork')}</Text>
                </div>
                <Text
                  as="a"
                  href={'https://goo.gl/maps/igkz6SntnDqaCDkf6'}
                  rel="noopener noreferrer"
                  target={'_blank'}
                  className="text-lg text-[#3767B1] hover:underline "
                >
                  {t('View in Google maps')}
                </Text>
              </div>
              <div className="mb-16 mt-8  md:mb-0">
                <Text
                  as="h3"
                  className=" text-medium text-gray-secondary mb-8 font-bold leading-[38px] "
                >
                  {t('Branch Office')}
                </Text>
                <div className="my-8 text-[#50586C]">
                  <Text>{t('44700 Nepal, Lalitpur Balkumari-08 KVD')}</Text>
                  <Text>{t('Complex 2F')}</Text>
                </div>
                <Text
                  as="a"
                  href={'https://goo.gl/maps/yE7r34VP9kYqWJq56'}
                  rel="noopener noreferrer"
                  target={'_blank'}
                  className="text-lg  text-[#3767B1] hover:underline"
                >
                  {t('View in Google maps')}
                </Text>
              </div>
            </div>
            <div className="grid gap-4 md:grid-rows-2">
              <div className="grid grid-cols-3 gap-4">
                <img
                  src={`${process.env.NEXT_PUBLIC_STATIC_URL}/office/office1.png`}
                  className="col-span-2 m-auto
                 h-56 w-full border border-solid border-gray-500 object-cover object-center"
                  alt="news"
                />
                <img
                  src={`${process.env.NEXT_PUBLIC_STATIC_URL}/office/office2.png`}
                  className="col-span-1 m-auto
                 h-56 w-full border border-solid border-gray-500 object-cover object-center"
                  alt="news"
                />
              </div>
              <img
                src={`${process.env.NEXT_PUBLIC_STATIC_URL}/office/office3.jpg`}
                className="m-auto
                h-56 w-full border border-solid border-gray-500 object-cover object-center"
                alt="news"
              />
            </div>
          </div>
          <div className="row-span-1 grid gap-4 md:grid-cols-3">
            {' '}
            <img
              src={`${process.env.NEXT_PUBLIC_STATIC_URL}/office/office4.jpg`}
              className="h-52 w-full border border-solid
                 border-gray-500 object-cover object-center md:col-span-2"
              alt="news"
            />
            <img
              src={`${process.env.NEXT_PUBLIC_STATIC_URL}/office/office5.png`}
              className="w-full border border-solid border-gray-500
                 object-cover object-center md:col-span-1 md:h-52"
              alt="news"
            />
          </div>
        </div>
      </section>
    );
  }
);

export { OurOffices };
