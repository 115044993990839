import { Text } from '@chakra-ui/react';
import { formatDateInWords } from 'lib';
import Link from 'next/link';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { LinkArrowIcon } from '../../../public';

type Props = {
  id: string;
  img: string;
  link: string;
  title: string;
  date: string;
  label: string;
  slug: string;
};

export const NewsCard = (props: Props) => {
  const { img, title, date, label, slug } = props;

  const { t, i18n } = useTranslation();

  return (
    <Link
      href={`/news/${slug}`}
      passHref
      className="group flex cursor-pointer flex-col transition duration-200 ease-in sm:max-w-full">

      <div className="container relative">
        <div className="absolute right-4 top-4 z-[99] hidden group-hover:block">
          <LinkArrowIcon />
        </div>
        <div className="xs:h-[200px] h-[165px] w-full sm:h-[165px] md:h-[180px]">
          <Image
            src={
              img
                ? img
                : `${process.env.NEXT_PUBLIC_STATIC_URL}/news/fallback.png`
            }
            className="group-hover:z-120 m-auto transform border border-solid border-gray-500 object-cover object-center transition duration-200 ease-in group-hover:z-50 group-hover:opacity-80 sm:group-hover:scale-105"
            alt={`article news wesionary ${title} ${label}`}
            layout="fill"
          />
        </div>

        <div className="textblock absolute bottom-4 left-2 z-[99] !bg-white p-2">
          <Text className="!text-black-secondary text-base font-semibold">
            {t(label)}
          </Text>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start">
        <p className="m-0 mt-[6px] text-sm text-[#222B45]">
          {formatDateInWords(date, i18n.language)}
        </p>
        <div className="flex items-start justify-start gap-3 no-underline">
          <h3 className="font-Poppins m-0 mr-3 line-clamp-2 text-ellipsis text-lg font-semibold text-[#000]">
            {title}
          </h3>
        </div>
      </div>

    </Link>
  );
};
