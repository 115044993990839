import {
  AWSIcon,
  Flutter,
  GCPIcon,
  GoIcon,
  NextIcon,
  Node,
  Python,
  ReactIcon,
  TerraFormIcon,
} from 'public/assets/images/svg';
import { typography } from 'ui';
import { AnimatedLink } from 'components/atoms';
import { officialUrls } from 'constants/officialUrl';

interface Props {
  className?: string;
}

const TechList = ({ className }: Props) => {
  return (
    <div
      className={`mt-8 flex flex-col gap-8 md:mr-4 md:flex-row  md:justify-around ${className}`}
    >
      <div className="md:text-center">
        <h3
          className="text-[#3767B1] md:text-center"
          style={{ ...typography?.mediumBold }}
        >
          Front-end
        </h3>
        <div className="mt-[26px] flex flex-wrap gap-[33px] md:w-[10rem] md:justify-center md:text-center">
          <div>
            <AnimatedLink href={officialUrls.reactJs}>
              <ReactIcon />
            </AnimatedLink>
          </div>
          <div>
            <AnimatedLink href={officialUrls.nextJs}>
              <NextIcon />
            </AnimatedLink>
          </div>
          <div className=" self-center ">
            <AnimatedLink href={officialUrls.flutter}>
              <Flutter />
            </AnimatedLink>
          </div>
        </div>
      </div>
      <div className="md:text-center">
        <h3
          className="text-[#3767B1] md:text-center"
          style={{ ...typography?.mediumBold }}
        >
          Back-end
        </h3>
        <div className="mt-[26px] flex flex-row flex-wrap justify-start gap-[33px] text-center md:w-[11rem] lg:justify-center">
          <div>
            <AnimatedLink href={officialUrls.goLang}>
              <GoIcon />
            </AnimatedLink>
          </div>
          <div>
            <AnimatedLink href={officialUrls.nodeJs}>
              <Node />
            </AnimatedLink>
          </div>
          <div>
            <AnimatedLink href={officialUrls.python}>
              <Python />
            </AnimatedLink>
          </div>
        </div>
      </div>
      <div className="md:text-center">
        <h3
          className="text-[#3767B1] md:text-center"
          style={{ ...typography?.mediumBold }}
        >
          Infrastructure
        </h3>
        <div className="mt-[26px] flex flex-row flex-wrap gap-[33px] text-center md:w-[12rem] lg:justify-center">
          <div>
            <AnimatedLink href={officialUrls.gcp}>
              <GCPIcon />
            </AnimatedLink>
          </div>
          <div>
            <AnimatedLink href={officialUrls.aws}>
              <AWSIcon />
            </AnimatedLink>
          </div>
          <div>
            <AnimatedLink href={officialUrls.terraform}>
              <TerraFormIcon />
            </AnimatedLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export { TechList };
