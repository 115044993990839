import { ReactNode } from 'react';
import { Input, Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { typography } from 'ui';
import { ErrorMessage } from '..';

interface InputProps {
  value?: string;
  isInvalid?: boolean;
  errorMessage?: string;
  onChange?: any;
  type?: string;
  name?: string;
  icon?: ReactNode;
  label?: string;
  required?: boolean;
  placeholder?: string;
  labelDescription?: string;
  rest?: any;
  id?: string;
  className?: string;
  labelClassName?: string;
  note?: string;
  disabled?: boolean;
}

const TextField = ({
  value,
  onChange,
  errorMessage,
  isInvalid,
  type,
  name,
  icon,
  label,
  required,
  placeholder,
  labelDescription,
  rest,
  id,
  className,
  labelClassName,
  note,
  disabled,
}: InputProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${labelDescription ? 'h-40' : 'h-32'} ${
        errorMessage ? 'mb-4' : ''
      }`}
    >
      <div className="flex items-center">
        {icon}
        <Text
          as="label"
          className={`
           ${icon && 'ml-[10px]'}
        font-Poppins text-gray-primary inline text-[18px] font-normal leading-[26px] ${labelClassName}`}
        >
          {label}
        </Text>
        {required && (
          <Text
            as="span"
            className={`font-Poppins text-gray-secondary pl-[3px] text-[18px] font-normal leading-[26px] ${labelClassName}`}
          >
            {t('(Required)')}
          </Text>
        )}
      </div>
      {labelDescription && (
        <Text
          {...typography?.extraSmall}
          className="mb-[5px] mt-[10px] text-[#50586C] "
          as="p"
        >
          {labelDescription}
        </Text>
      )}
      <Input
        id={id}
        {...rest}
        w="100%"
        focusBorderColor="#960912"
        disabled={disabled}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        isInvalid={isInvalid}
        errorBorderColor="#960912"
        placeholder={placeholder}
        _placeholder={{
          fontFamily: 'Poppins',
        }}
        className={`font-Poppins mt-3 rounded-lg border p-4 ${
          !isInvalid && '!border-gray-secondary'
        } focus:border-primary-500 focus:outline-primary-400 focus:border focus:outline-2 ${className}`}
        height="56px"
        style={{
          maxWidth: '-webkit-fill-available',
        }}
      />
      {note ? <Note note={note} /> : null}
      {errorMessage && <ErrorMessage error={errorMessage} />}
    </div>
  );
};

export { TextField };

const Note = ({ note }: { note?: ReactNode }) => {
  if (!note) return null;
  return (
    <div className="text-gray-secondary -mb-3 text-sm">
      {typeof note === 'string' ? <p>{note}</p> : note}
    </div>
  );
};
