import { Blog } from 'lib';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { BlogListItem, NewsCard } from '../..';

export function RelatedBlogs({
  blogs,
  itemType,
}: {
  blogs: Blog[];
  itemType: 'card' | 'list';
}) {
  const { t } = useTranslation();

  if (!blogs || !blogs.length) return null;

  return (
    <div className="related-blogs flex flex-col gap-2">
      {itemType === 'card' ? (
        <>
          <div className="flex items-center justify-start gap-4">
            <div className="bg-primary-500 !h-[38px] !w-[6px] text-red-500" />
            <h2 className="font-Albert text-[32px] font-semibold">
              {t('Related Blogs')}
            </h2>
          </div>
          <div className="blog-card flex w-full flex-wrap items-start justify-start gap-[30px]">
            {blogs?.map((blog) => (
              <article
                key={blog.id}
                className="mt-8 w-full sm:w-[46%] lg:w-[30%]"
              >
                <NewsCard
                  img={blog.banner_image}
                  label={blog.category.name}
                  title={blog.title}
                  date={blog.published_at}
                  link={`/news/${blog.slug}`}
                  slug={blog.slug}
                  id={blog.id}
                />
              </article>
            ))}
          </div>
        </>
      ) : null}

      {itemType === 'list' ? (
        <>
          <h2 className="font-Rubik m-0 mb-[15px] text-base font-semibold">
            {t('Related Blogs')}
          </h2>
          <div className="blog-card flex flex-col flex-wrap gap-[30px]">
            {blogs?.map((blog) => (
              <BlogListItem
                bannerImage={blog.banner_image}
                category={blog.category.name}
                title={blog.title}
                date={blog.updated_at}
                link={`/news/${blog.slug}`}
                key={blog.id}
              />
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
}
