import { ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'next-i18next';
import { Text } from '@chakra-ui/react';
import { typography, Button, NewsCard } from 'ui';
import { useRouter } from 'next/router';
import { Blog } from 'lib';

interface NewsProps {
  id: string;
  className?: string;
  newsList: Blog[];
}

const News = forwardRef(
  ({ id, className, newsList }: NewsProps, ref: ForwardedRef<HTMLElement>) => {
    const { t } = useTranslation();

    const router = useRouter();

    if (!newsList || newsList?.length === 0) return null;

    return (
      <section
        id={id}
        ref={ref}
        className={`mb-[60px] mt-[60px] pr-0 lg:pr-[30px] xl:pr-[60px] ${className}`}
      >
        <div className="border-l-[6px] border-[#3767B1] md:pl-[16px]">
          <Text
            as="h2"
            {...typography?.fontPoppins}
            className="pl-[16px]  text-2xl font-bold md:border-l-0 md:border-none md:pl-0 md:text-3xl"
          >
            {t('News')}
          </Text>
        </div>
        <div className="blog-card flex w-full flex-wrap items-start justify-start gap-[30px]">
          {newsList?.map((news) => (
            <article
              key={news.id}
              className="mt-8 w-full sm:w-[46%] lg:w-[30%]"
            >
              <NewsCard
                id={news.id}
                img={news.banner_image}
                link={news.slug}
                date={news.published_at}
                title={t(news.title)}
                label={t(news.category.name)}
                slug={news.slug}
              />
            </article>
          ))}
        </div>
        <div className="mt-[4rem] flex items-center justify-center md:mt-[32px]">
          <Button
            type="secondary"
            className="!w-full md:!w-auto"
            onClick={() => {
              router.push(`/news`);
            }}
          >
            {t('View all news')}
          </Button>
        </div>
      </section>
    );
  }
);

export { News };
