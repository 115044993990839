import { Text } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

export interface LabelProps {
  label?: ReactNode;
  required?: boolean;
  labelClassName?: string;
  icon?: ReactNode;
}

export const Label = ({
  label,
  required,
  labelClassName,
  icon,
}: LabelProps) => {
  const { t } = useTranslation();
  return (
    <>
      {label ? (
        <div className="flex items-center">
          {icon}
          <Text
            as="label"
            className={`
           ${icon && 'ml-[10px]'}
        font-Poppins text-gray-primary inline text-[14px] font-normal leading-[26px] ${labelClassName}`}
          >
            {label}
          </Text>
          {required && (
            <Text
              as="span"
              className={`font-Poppins text-gray-secondary pl-[3px] text-[14px] font-normal leading-[26px] ${labelClassName}`}
            >
              {t('(Required)')}
            </Text>
          )}
        </div>
      ) : null}
    </>
  );
};
