import { Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { ForwardedRef, forwardRef } from 'react';
import { PageProps } from 'shared/interfaces';
import { typography } from 'ui';
import { MEMBER_LIST } from 'utils/ceo';

const CEO = forwardRef(({ id }: PageProps, ref: ForwardedRef<HTMLElement>) => {
  const { t } = useTranslation();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const router = useRouter();
  const lang = router.locale;
  const margin = lang === 'ja' ? 'lg:mt-[60px]' : 'lg:mt-[28px]';

  return (
    <section
      ref={ref}
      id={id}
      className="mt-16 mr-0 lg:mr-[30px] xl:mr-[60px] 3xl:mr-0"
    >
      <div className="mb-8 border-l-[6px] border-primary-500 pl-4">
        <Text
          as="h2"
          className="mb-4"
          {...(isMobile ? typography?.h5 : typography?.h4)}
        >
          {t('Member Introduction')}
        </Text>
      </div>

      <div className=" mb-[12px] flex flex-row  items-center gap-[24px] md:mb-[24px]">
        <div className="min-[1440px]:min-w-[350px] w-[126px] md:w-[222px] lg:min-w-[270px]">
          <img
            src={`${process.env.NEXT_PUBLIC_STATIC_URL}/ceo_new.png`}
            alt={t('Picture of CEO')}
          />
        </div>

        <div className="flex max-w-[796px] flex-col ">
          <Text
            className="whitespace-pre-line text-[#000000]"
            {...typography?.mediumBold}
          >
            {t('永田 一郎')}
          </Text>
          <Text
            className="whitespace-pre-line text-[#000000]"
            {...typography?.mediumBold}
          >
            {'Ichiro Nagata'}
          </Text>
          <Text
            className="mt-[6px] whitespace-pre-line text-gray-secondary"
            {...typography?.mediumBold}
          >
            {t('CEO')}
          </Text>
          <Text
            className={`min-[1440px]:mt-[60px] mt-[24px] hidden whitespace-pre-line text-justify text-gray-secondary ${margin} lg:flex`}
            {...typography?.normal}
          >
            {t(
              'Over the course of more than 18 years, he has worked on a wide range of projects such as new business development for toC/toB and business DX conversion etc at both startups &  major business companies.While involved in development as an engineer, he was also involved in system construction, management, business design, hypothesis testing, etc., and learned a wide range of business launch and growth. He has sold three businesses so far.He founded wesionaryTEAM to provide a co-creation platform, believing that co-creation can produce products more effectively and ideas can be verified in the market.'
            )}
          </Text>
        </div>
      </div>

      <div className="flex flex-col gap-1">
        <Text
          className="mb-[24px] flex whitespace-pre-line text-justify text-gray-secondary lg:hidden"
          {...typography?.normal}
        >
          {t(
            'Over the course of more than 18 years, he has worked on a wide range of projects such as new business development for toC/toB and business DX conversion etc at both startups &  major business companies.While involved in development as an engineer, he was also involved in system construction, management, business design, hypothesis testing, etc., and learned a wide range of business launch and growth. He has sold three businesses so far.He founded wesionaryTEAM to provide a co-creation platform, believing that co-creation can produce products more effectively and ideas can be verified in the market.'
          )}
        </Text>
        <Text className="mb-4 text-[#000000] " {...typography?.normalBold}>
          {t(`Message from CEO`)}
        </Text>

        <Text className="mb-4 text-[#000000] " {...typography?.normal}>
          {t(
            `“Making co-creation a norm and playing a role in enriching society”`
          )}
        </Text>
        <Text className="mb-4 text-gray-secondary " {...typography?.normal}>
          {t(
            'In this highly unpredictable age of VUCA, we want to deliver more value to society by enabling people to more effectively shape and validate their ideas and adjust their strategies quickly and flexibly.'
          )}
        </Text>
        <Text className="mb-4 text-gray-secondary " {...typography?.normal}>
          {t(
            'For that purpose, we believe that it is essential for companies to cooperate effectively with each other and with individuals to enhance each other.'
          )}
        </Text>
        <Text
          className="mb-4 whitespace-pre-line text-gray-secondary"
          {...typography?.normal}
        >
          {t(
            'Neither companies nor individuals are perfect.\nRapid changes are occurring in both the business environment and technical innovations'
          )}
        </Text>
        <Text className="mb-4 text-gray-secondary " {...typography?.normal}>
          {t(
            'Through collaborative practice, systematization, and platformisation, we are striving to lower those hurdles.'
          )}
        </Text>
        <Text className="mb-4 text-gray-secondary " {...typography?.normal}>
          {t(
            'With this, it will be easier to achieve what companies and individuals could not do alone, and many new realizations will emerge. We are confident that we can make a significant contribution to enriching the future of people and society.'
          )}
        </Text>

        <Text
          {...typography?.normal}
          className="whitespace-pre-line text-gray-secondary"
        >
          {t(
            'The potential for co-creation is infinite.\nAs a tech company providing a business co-creation platform, we continue to strive with all our might for the update of society.'
          )}
        </Text>
      </div>

      <div className=" mt-[32px] max-w-[1084px] md:grid md:grid-cols-2  md:gap-x-[24px] md:pr-4  lg:mt-[32px] lg:grid-cols-2 lg:gap-x-[24px] lg:gap-y-[32px] 2xl:max-w-[1440px] 3xl:max-w-full">
        {MEMBER_LIST?.map((item: any, index) => {
          return (
            <Flex
              direction="column"
              gap={24}
              key={index}
              className=" mb-[24px] md:mb-0"
            >
              <Flex gap={16} className="flex flex-row ">
                <img
                  src={item.image}
                  alt={item.name_en}
                  className="h-[126px] min-h-full w-[126px] md:h-[162px]  md:w-[162px]"
                />
                <Flex direction="column" justify="center" gap={4}>
                  <Text
                    {...typography?.normalBold}
                    className="text-grey-secondary"
                  >
                    {t(item.name_ja)}
                  </Text>
                  <Text
                    {...typography?.normalBold}
                    className="text-grey-secondary"
                  >
                    {item.name_en}
                  </Text>
                  <Text {...typography?.small} className="text-grey-secondary">
                    {t(item.position)}
                  </Text>
                </Flex>
              </Flex>
              <Flex direction="column" gap={24}>
                {item.description?.map((description: string, index: number) => {
                  return (
                    <Text
                      key={index}
                      className="text-justify whitespace-pre-line text-gray-secondary"
                      {...typography?.normal}
                      dangerouslySetInnerHTML={{
                        __html: t(description) || '',
                      }}
                    />
                  );
                })}
              </Flex>
              <Flex direction="column">
                {item.media_link?.map((link: string, index: number) => {
                  return (
                    <Text
                      className="text-primary-500"
                      key={index}
                      {...typography?.normalBold}
                      dangerouslySetInnerHTML={{
                        __html: link || '',
                      }}
                    />
                  );
                })}
              </Flex>
            </Flex>
          );
        })}
      </div>
    </section>
  );
});

export { CEO };
