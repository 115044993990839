import { ReactNode } from 'react';
import { typography } from 'ui';
import { Text } from '@chakra-ui/react';

interface ContactProps {
  icon?: any;
  topText?: string;
  lowerTextFirst?: string;
  lowerTextSecond?: string;
  buttonComponent?: ReactNode;
  borderBottom?: string;
  borderRight?: string;
  weekday?: string;
  noGap?: boolean;
  margin?: string;
  marginTop?: string;
}

const ContactCard = ({
  icon,
  topText,
  lowerTextFirst,
  lowerTextSecond,
  buttonComponent,
  marginTop,
  weekday,

  margin,
}: ContactProps) => {
  return (
    <div className="2xl:w-md relative mb-8  max-w-full md:mr-4 lg:mb-0 lg:mr-0 lg:max-w-[320px] 2xl:w-[370px]">
      <div className="flex items-center ">
        <div className="  flex h-10 w-10 items-center justify-center rounded-lg bg-[#EAF4FD] lg:h-[60px] lg:w-[60px]">
          {icon}
        </div>
        <Text
          as="h3"
          {...typography?.h5}
          className="ml-2 whitespace-pre-line font-Rubik text-gray-primary lg:ml-4"
        >
          {topText}
        </Text>
      </div>
      <div className="min-h-fit md:min-h-[44px]">
        <Text
          as="p"
          {...typography?.small}
          className={`mt-4 inline whitespace-pre-line font-Poppins  text-sm text-gray-secondary lg:${
            margin ? margin : 'mb-[17px]'
          } lg:block`}
        >
          {lowerTextFirst}
        </Text>
        {lowerTextSecond && (
          <Text
            as="p"
            {...typography?.small}
            className="mt-[-4px]4 mb-0 block whitespace-pre-line  font-Poppins text-sm text-gray-secondary lg:mb-[0px] lg:inline  lg:whitespace-normal"
          >
            {lowerTextSecond}
          </Text>
        )}
        {weekday && (
          <Text
            as="span"
            {...typography?.small}
            className={`${margin}  inline font-Poppins !font-semibold text-gray-secondary`}
          >
            {weekday}
          </Text>
        )}
      </div>
      {buttonComponent && <div className={marginTop}>{buttonComponent}</div>}
    </div>
  );
};

export { ContactCard };
