import { ReactNode } from 'react';
import { Text, Textarea } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { ErrorMessage } from '..';

interface InputProps {
  value?: string;
  isInvalid?: boolean;
  errorMessage?: string;
  onChange?: any;
  name?: string;
  icon?: ReactNode;
  label?: string;
  required?: boolean;
  placeholder?: string;
  id?: string;
  labelClassName?: string;
  rest?: any;
  disabled?: boolean;
  resize?: boolean;
}

const TextArea = ({
  value,
  onChange,
  errorMessage,
  isInvalid,
  id,
  name,
  icon,
  label,
  required,
  placeholder,
  labelClassName,
  disabled,
  rest,
  resize = true,
}: InputProps) => {
  const { t } = useTranslation();

  return (
    <div className="font-Poppins mb-6">
      <div className="flex items-center">
        {icon}
        <Text
          as="label"
          className={`
           ${icon && 'ml-[10px]'}
        font-Poppins text-gray-primary inline text-[18px] font-normal leading-[26px] ${labelClassName}`}
        >
          {label}
        </Text>
        {required && (
          <Text
            as="span"
            className={`font-Poppins text-gray-secondary pl-[3px] text-[18px] font-normal leading-[26px] ${labelClassName}`}
          >
            {t('(Required)')}
          </Text>
        )}
      </div>
      <Textarea
        {...rest}
        id={id}
        rows={5}
        w="100%"
        focusBorderColor="#960912"
        name={name}
        value={value}
        onChange={onChange}
        isInvalid={isInvalid}
        errorBorderColor="#960912"
        placeholder={placeholder}
        disabled={disabled}
        resize={resize ? 'vertical' : 'none'}
        _placeholder={{
          color: '#8F9BB3',
          fontFamily: 'Poppins',
          fontSize: '16px',
          lineHeight: '24px',
        }}
        className={`font-Poppins mt-3 rounded-lg border p-4 ${
          !isInvalid && '!border-gray-secondary'
        } focus:border-primary-500 focus:outline-primary-400 focus:border focus:outline-2`}
        style={{
          maxWidth: '-webkit-fill-available',
        }}
      />
      {errorMessage && <ErrorMessage error={errorMessage} />}
    </div>
  );
};

export { TextArea };
