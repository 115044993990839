export const generatePageNumbers = (
  dataLength: number,
  itemsPerPage: number
) => {
  const pageCount = Math.ceil(dataLength / itemsPerPage);
  return Array.from({ length: pageCount }, (_, i) => i + 1);
};

export const offset = (currentPage: number, pageNumbers: number[]) => {
  const clickedIndex = pageNumbers.indexOf(currentPage);
  const startIndex = Math.max(0, clickedIndex - 1);
  const endIndex = Math.min(clickedIndex + 2, pageNumbers.length - 1);

  const slicedNumbers = pageNumbers.slice(startIndex, endIndex + 1);

  if (slicedNumbers.length < 6) {
    const padding = 6 - slicedNumbers.length;
    const previousArray = pageNumbers.slice(
      Math.max(0, startIndex - padding),
      startIndex
    );
    const nextArray = pageNumbers.slice(endIndex + 1, endIndex + 1 + padding);

    return [...previousArray, ...slicedNumbers, ...nextArray];
  }

  return slicedNumbers;
};

export function generateSearchParams(
  params: Record<string, string | number | boolean | undefined | null>
): string {
  const searchParams = new URLSearchParams();
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key];
      if (value !== undefined && value !== 'undefined' && value !== null) {
        searchParams.append(key, value.toString());
      }
    }
  }

  return searchParams.toString();
}
